import * as React from "react";
import {
    required,
    useDataProvider,
    useNotify, 
    useRefresh,
    useShowController,
    useRecordContext,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    FormDataConsumer,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    BulkDeleteButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FunctionField,
    DateField,
    ChipField,
    Button,
} from 'react-admin';
import { Grid } from '@mui/material';
import { CheckCircle, HighlightOff } from '@mui/icons-material';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";
import { REQUESTS_CHOICE } from "../constants/constants";

const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8888';

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const RequestList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Requests' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<RequestFilter />} title="Requests">
                    <Datagrid bulkActionButtons={<BulkActionButtons />}>
                        <TextField source="object_type" />
                        <ReferenceField label="Name" source="object_id" reference="benefits" link="show" >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Requester" source="requester._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Approver" source="approver._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Status" source="status" reference="request-status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Approved' ? 
                                    (<ChipField source="title" color="primary" />) : record.id === 'Rejected' ?  (<ChipField source="title" color="error" />)  : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        <ApproveButton label="Approve" />
                        <RejectButton label="Reject" />
                        <FunctionField label="Edit" render={
                                record => record.id === 'Pending' ? <EditButton /> : <></> } />
                        
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

const ApproveButton = (props) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleApprove = (e, id) => {
        dataProvider.approve('requests', { id: id })
            .then(({ data }) => {
                refresh();
                notify('Approved successfully');
            })
            .catch(error => {
                refresh();
                notify(error.toString());
            })

        
    }

    return (
        record.status == 'Pending' ? <Button variant="outlined" color="success" startIcon={<CheckCircle />} onClick={(e) => handleApprove(e, record.id)}>Approve</Button> : <></>
    );
};

const RejectButton = (props) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleReject = (e, id) => {
        dataProvider.reject('requests', { id: id })
            .then(({ data }) => {
                refresh();
                notify('Approved successfully');
            })
            .catch(error => {
                refresh();
                notify(error.toString());
            })
    }

    return (
        record.status == 'Pending' ? <Button variant="outlined" color="error" startIcon={<HighlightOff />} onClick={(e) => handleReject(e, record.id)}>Reject</Button> : <></>
    );
};



export const RequestShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="requests" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                    <TextField source="object_type" />
                        <ReferenceField label="Name" source="object_id" reference="benefits" link="show" >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Requester" source="requester._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Approver" source="approver._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Status" source="status" reference="request-status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Approved' ? 
                                    (<ChipField source="title" color="primary" />) : record.id === 'Rejected' ?  (<ChipField source="title" color="danger" />)  : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const RequestEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="requests" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const RequestCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="requests" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    return (
        <SimpleForm {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectInput source="object_type" choices={REQUESTS_CHOICE} label="Type" optionText="name" optionValue="id" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="object_id" label="Object Name" reference="benefits" filter={{ type: "user_type" }} perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.name}`} validate={required()} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="user" reference="users" filter={{ role: "user" }} perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} validate={required()} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="requester" reference="users" filter={{ role: "admin" }} validate={[required()]} perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} validate={required()} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="approver" reference="users" filter={{ role: "exhibitor" }} validate={[required()]} perPage={9999} fullWidth>
                        <AutocompleteInput format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} validate={required()} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const RequestFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="requester" reference="users" filter={{ role: "admin" }} alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="approver" reference="users" filter={{ role: "exhibitor" }} alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="user" reference="users" filter={{ role: "user" }} alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });