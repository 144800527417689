import * as React from "react";
import {
    required,
    useListContext,
    usePermissions,
    TopToolbar,
    CreateButton,
    ExportButton,
    useShowController,
    List, 
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    TextField, 
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    BulkDeleteButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    DateField,
    ChipField,
    downloadCSV
} from 'react-admin';
import { Grid } from '@mui/material';
import Breadcrumb from '../components/breadcrumb';
import PostShowActions from "../components/postShowActions";
import * as jsonExport from "jsonexport/dist";

const exporter = elements => {
    const elementsForExport = elements.map(data => {
        let element = {
            id: data.id,
            nickname: data.nickname,
            fullname: data.fullname,
            email: data.email,
            phone: data.phone,
            company: data.user && data.user.company ? data.user.company : '',
            country: data.user && data.user.country ? data.user.country : '',
            description: data.description || '',
            user_id: data.user && data.user._id ? data.user._id : '',
            owner_id: data.owner && data.owner._id ? data.owner._id : '',
            owner_name: data.owner && data.owner.fullname ? data.owner.fullname : '',
            owner_email: data.owner && data.owner.email ? data.owner.email : '',
            owner_company: data.owner && data.owner.company ? data.owner.company : '',
            date_created: data.createdAt,
        }

        return element;
    });
    jsonExport(elementsForExport, {
        headers: ['id', 'nickname', 'fullname', 'email', 'phone', 'company', 'country', 'description', 'user_id', 'owner_id', 'owner_name', 'owner_email', 'owner_company', 'date_created'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'contacts'); // download as 'users.csv` file
    });
};

const ListActions = () => {
    const { total, isLoading } = useListContext();
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            <CreateButton />
            {permissions === "admin" && <ExportButton exporter={exporter} disabled={isLoading || total === 0} maxResults={999999} />}
        </TopToolbar>
    );
}

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} undoable={false} />
)

export const ContactList = props => (
    <Grid container spacing={2}>
        <Breadcrumb title={ 'Contacts' } />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List filters={<ContactFilter />} actions={<ListActions />} title="Contacts">
                    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                        <TextField source="nickname" />
                        <TextField source="fullname" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Owner" source="owner._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <TextField source="description" />
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                        <EditButton />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    </Grid>
);

export const ContactShow = (props) => {
    const {
        record
    } = useShowController(props);

    return (
        <Show {...props} actions={<PostShowActions resource="contacts" isEdit={true} />}>
            <Grid container paddingBottom={2} spacing={2}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <TextField source="nickname" />
                        <TextField source="fullname" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <ReferenceField label="User" source="user._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <ReferenceField label="Owner" source="owner._id" reference="users" link="show" >
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </ReferenceField>
                        <TextField source="description" />
                        <ReferenceField label="Status" source="status" reference="status" link={ false } >
                            <FunctionField label="Status" render={
                                record => record.id === 'Active' ? 
                                    (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                            } />
                        </ReferenceField>
                        <DateField label="Created At" source="createdAt" showTime={true} emptyText="N/A" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export const ContactEdit = props => {
    return (
        <Edit {...props} actions={<PostShowActions resource="contacts" />}>
            <CreateEditForm {...props} />
        </Edit>
    );
}

export const ContactCreate = props => {
    return (
        <Create {...props} actions={<PostShowActions resource="contacts" />}>
            <CreateEditForm {...props} />
        </Create>
    );
}

const CreateEditForm = props => {
    const [defaultValues, setDefaultValues] = React.useState({});
    const handleChange = (
        value,
        record
    ) => {
        if (value && record && record.fullname) {
            setDefaultValues({
                user: value,
                nickname: record.fullname,
                fullname: record.fullname,
                email: record.email,
                phone: record.phone
            })
        }
    };

    return (
        <SimpleForm {...props} defaultValues={defaultValues}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReferenceInput source="user" reference="users" perPage={9999} fullWidth>
                        <AutocompleteInput onChange={ handleChange } format={ formatReferenceDisplay } validate={[required()]} filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="nickname" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="fullname" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="email" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="phone" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="owner" reference="users" perPage={9999} fullWidth>
                        <AutocompleteInput validate={[required()]} format={ formatReferenceDisplay } filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline={true} source="description" defaultValue="" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput source="status" reference="status">
                        <AutocompleteInput validate={[required()]} filterToQuery={filterToQuery} optionText="title" optionValue="id" fullWidth />
                    </ReferenceInput>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export const ContactFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="user" reference="users" alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="owner" reference="users" alwaysOn>
            <AutocompleteInput filterToQuery={filterToQuery} optionText={choice => `${choice.fullname} (${choice.email})`} optionValue="id" fullWidth />
        </ReferenceInput>
    </Filter >
);

const formatReferenceDisplay = function (value) {
    return value && value._id ? value._id : value;
};

const filterToQuery = searchText => ({ keyword: searchText });