import * as React from "react";
import {
    List, 
    SimpleShowLayout,
    ArrayField,
    Datagrid, 
    Show,
    Filter,
    TextField,
    RichTextField,
    TextInput,
    SelectArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ExportButton,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    UrlField,
    ImageField,
    FunctionField,
    EmailField,
    useListContext,
    useRecordContext,
    downloadCSV
} from 'react-admin';
import { Grid, Button } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import ContentFilter from '@mui/icons-material/FilterList';
import { JsonField } from "react-admin-json-view";
import Breadcrumb from '../components/breadcrumb';
import SyncButton from "../components/syncButton";
import PostShowActions from "../components/postShowActions";
import * as jsonExport from "jsonexport/dist";

const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8888';

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const PostFilterButton = () => {
    const { showFilter } = useListContext();
    return (
        <Button
            size="small"
            color="primary"
            onClick={() => {
                showFilter("product_type");
                showFilter("export_market");
                showFilter("capacity_of_factory");
                showFilter("certificate");
                showFilter("material");
            }}
            startIcon={<ContentFilter />}
        >
            Filter
        </Button>
    );
};

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'location', 'terms').then(locations => {
        const data = records.map(function(post) {
            let locationText = '';
            for (let i = 0; i < locations.length; i++) {
                const location = locations[i];
                console.log(location, post.location);
                if (post.location.includes(location.term_id)) {
                    if (locationText == "") {
                        locationText = location.name;
                    } else {
                        locationText = locationText + ", " +location.name;
                    }                    
                }   
            }

            let element = {
                id: post.post_id,
                name: post.title,
                booth: post.booth,
                phone: post.phone,
                email: post.email,
                address: post.address,
                location: locationText,
            }
            element.qr_code_url = apiUrl + '/qr-code?data=EXHIBITOR' + post.post_id;
            element.pdf_file_url = apiUrl + '/partnerships/pdf/' + post.post_id;
    
            return element;      
        });

        return jsonExport(data, {
            headers: ['id', 'name', 'phone', 'email', 'address', 'booth', 'location', 'qr_code_url', 'pdf_file_url'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'partnerships'); // download as 'posts.csv` file
        });;
    });
};

const ListActions = (props) => {
    return (
      <TopToolbar {...props}>
        <PostFilterButton />
        <ExportButton exporter={exporter} maxResults={999999} />
        <SyncButton resource="partnerships" />
      </TopToolbar>
    );
  };

export const PartnershipList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Exhibitors' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List empty={ false } filters={<PartnershipFilter />} actions={ <ListActions />} title="Exhibitors" >
                        <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="show">
                            <TextField source="post_id" />
                            <RichTextField source="title" />
                            <FunctionField label="Booth" render={record => `${record.venue} - ${record.street} - ${record.booth}`} />
                            <EmailField source="email" />
                            <EmailField source="manager_email" />
                            <TextField source="phone" />
                            <ReferenceArrayField label="Season" reference="terms" source="season">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Location" reference="terms" source="location">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <FunctionField label="Total of likes" render={record => record.likes ? record.likes.length : 0} />
                            <ReferenceField source="status" reference="post-status">
                                <FunctionField label="Status" render={
                                    record => record.id === 'publish' ? 
                                        (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                                } />
                            </ReferenceField>
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

const PostTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (<span>Exhibitor #{record.id}</span>);
};

export const PartnershipShow = (props) => (
    <Show {...props} title={ <PostTitle /> } actions={<PostShowActions resource="partnerships" isEdit={false} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <RichTextField source="title" />
                    <UrlField source="link" />
                    <RichTextField source="slug" />
                    <ReferenceArrayField label="Season" reference="terms" source="season">
                        <SingleFieldList>
                            <ChipField source="name" color="secondary" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField label="Location" reference="terms" source="location">
                        <SingleFieldList>
                            <ChipField source="name" color="secondary" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField label="Bitrix Id" source="bitrix_id" />
                    <ReferenceField source="status" reference="post-status">
                        <FunctionField label="Status" render={
                            record => record.id === 'publish' ? 
                                (<ChipField source="title" color="primary" />) : (<ChipField source="title" color="default" />) 
                        } />
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <FunctionField label="Booth" render={record => `${record.venue} - ${record.street} - ${record.booth}`} />
                    <TextField source="phone" />
                    <EmailField source="email" />
                    <EmailField source="manager_email" />
                    <TextField source="address" />
                    <UrlField source="website" />
                    <UrlField source="hopefairs_link" />
                    <TextField source="production_facilities" />
                    <TextField source="number_of_employees" />
                    <TextField source="moq" />
                    <UrlField source="facebook" />
                    <UrlField source="linkedin" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <ReferenceField source="featured_media" reference="media">
                        <ImageField source="source_url" title="Featured" emptyText="No Image" />
                    </ReferenceField>
                    <ReferenceField label="Logo" source="logo.ID" reference="media">
                        <ImageField source="source_url" title="Featured" emptyText="No Image" />
                    </ReferenceField>
                </SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <ReferenceArrayField label="Product Types" reference="terms" source="product_type" link="show">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Capacity of factory" reference="terms" source="capacity_of_factory" link="show">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Certificates" reference="terms" source="certificate" link="show">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Export Markets" reference="terms" source="export_market">
                                <SingleFieldList linkType={false}>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField label="Materials" reference="terms" source="material">
                                <SingleFieldList>
                                    <ChipField source="name" color="secondary" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ArrayField label="Gallery" source="gallery">
                    <SingleFieldList linkType={false}>
                        <ReferenceField label="Image" source="ID" reference="media" link={false}>
                            <ImageField source="source_url" title="Featured" emptyText="No Image" />
                        </ReferenceField>
                    </SingleFieldList>
                </ArrayField>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <RichTextField source="except" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <RichTextField source="content" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <JsonField 
                        source="meta" 
                        reactJsonOptions={{
                            collapsed: true,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <JsonField 
                        source="_links" 
                        label="Links" 
                        reactJsonOptions={{
                            collapsed: true,
                        }}
                    />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
                <SimpleShowLayout>
                    <FunctionField label="Total of likes" render={record => record.likes ? record.likes.length : 0} />
                    <ReferenceArrayField label="User likes" reference="users" source="likes">
                        <SingleFieldList>
                            <TextField source="fullname" />
                            <br />
                            <TextField source="email" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

export const PartnershipFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
        <ReferenceArrayInput alwaysOn source="season" reference="terms" filter={{ taxonomy: 'season' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput alwaysOn source="location" reference="terms" filter={{ taxonomy: 'location' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="product_type" reference="terms" filter={{ taxonomy: 'product_type' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="capacity_of_factory" reference="terms" filter={{ taxonomy: 'capacity_of_factory' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="certificate" reference="terms" filter={{ taxonomy: 'certificate' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="export_market" reference="terms" filter={{ taxonomy: 'export_market' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="material" reference="terms" filter={{ taxonomy: 'material' }}>
            <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceInput alwaysOn source="status" reference="post-status">
            <SelectInput optionText="title" optionValue="id" fullWidth />
        </ReferenceInput>
    </Filter >
);
